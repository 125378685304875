<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="displayCopyIcon" v-bind="attrs"
                v-on="on" class="clickable" @click="copyTextToClipBoard" @mouseout="reset">mdi-content-copy</v-icon>
            <span v-if="displayCopyText" v-bind="attrs"
              v-on="on" class="clickable" @click="copyTextToClipBoard" @mouseout="reset">{{ textToCopy }}</span>
        </template>
        <span>{{ copyText }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "CopyText",
    props: {
        textToCopy:{
            type: String,
            optional: true,
        },
        displayCopyIcon:{
            type: Boolean,
            optional: true,
            default: false
        },
        displayCopyText:{
            type: Boolean,
            optional: true,
            default: false
        }
    },
    data() {
        return {
            copyText: "Klik om te kopiëren",
        }
    },
    methods: {
        copyTextToClipBoard() {
            navigator.clipboard.writeText(this.textToCopy)
            this.copyText = "Gekopieerd"
        },
        reset() {
            this.copyText = 'Klik om te kopiëren'
        }
    },
}
</script>