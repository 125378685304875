<template>
  <div>
    <OrganizationDetailHeaderComponent :organizationId="organizationId" />
    <v-row>
      <v-col cols="12" lg="7" class="d-flex">
        <OrganizationInformationComponent :organizationId="organizationId" class="flex-grow-1" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrganizationDetailHeaderComponent from "@/components/organizationDetail/OrganizationDetailHeaderComponent.vue"
import OrganizationInformationComponent from "@/components/organizationDetail/OrganizationInformationComponent.vue"
import routeNames from "@/router/RouteNames"
import {authenticatedUser} from "@/shared/utils/authenticationUtils";

export default {
  name: "OrganizationDetail.vue",
  props: {
    organizationId: {
      type: String,
      required: false,
      default: authenticatedUser().organizationId
    },
  },
  components: {
    OrganizationDetailHeaderComponent,
    OrganizationInformationComponent,
  },
  data() {
    return {
      routeNames,
    }
  },
}
</script>
