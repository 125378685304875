<template>
    <BasicInformationCard title="Bedrijfsinformatie"
        :editRouterLink="{ name: routeNames.ORGANIZATION_DETAIL_EDIT_ORGANIZATION_INFORMATION, params: { organizationId: organizationId } }"
        :promises.sync="promises">
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="6">
                                <BasicInformationItem description="Registratie datum"
                                    :value="applyDateFormat(organizationInformation.registrationDate)" />
                            </v-col>

                            <v-col cols="6">
                                <BasicInformationItem description="Adres" :value="organizationInformation.address">
                                    <template v-slot:value-display="slotProps">
                                        <p v-if="slotProps.value" class="subtitle-1 font-weight-medium black--text">
                                            {{ slotProps.value.street }} {{ slotProps.value.number }}
                                            {{ slotProps.value.bus }}
                                            <br />
                                            {{ slotProps.value.postalCode }}
                                            {{ slotProps.value.city }} {{ slotProps.value.region }}
                                            <br />
                                            <country-flag v-if="slotProps.value.countryCode"
                                                :country="slotProps.value.countryCode" size="small" rounded />
                                            {{ translateNationalitiy(slotProps.value.countryCode) }}
                                        </p>
                                    </template>
                                </BasicInformationItem>
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Btw nummer" :value="organizationInformation.vatNumber" />
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Bankrekening" :value="organizationInformation.bankAccountNumber" />
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Contact Email" :value="organizationInformation.contactEmail" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <GoogleMapsFrame :mapsURL="getGoogleMapsUrl(organizationInformation)" 
                    unavailableMessage="Er is geen adres beschikbaar" :staySmallAboveMedium=true />
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from "../shared/BasicInformationCard.vue"
import BasicInformationItem from "../shared/BasicInformationItem.vue"
import routeNames from "@/router/RouteNames"
import { translateNationalitiy } from "@/shared/utils/translateUtils"
import CountryFlag from "vue-country-flag"
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { getGoogleMapsUrl } from "@/shared/utils/requestUtils"
import GoogleMapsFrame from "@/components/shared/GoogleMapsFrame.vue"
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp"

export default {
    name: "OrganizationInformationComponent",
    props: {
        organizationId: {
            type: String,
            required: true,
        },
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag,
        GoogleMapsFrame,
    },
    data() {
        return {
            routeNames,
            promises: [this.getOrganizationInformationDto()],
            organizationInformation: {},
        }
    },

    methods: {
        translateNationalitiy,
        applyDateFormat,
        getGoogleMapsUrl,
        getOrganizationInformationDto() {
            return this.$store.dispatch("organizationModule/fetchOrganizationInformation", this.organizationId)
                .then(result => {
                    this.organizationInformation = {
                        ...result,
                        registrationDate: DetailedTimeStamp.fromJson(result?.registrationDate).getUTCTimeAsDate()
                    }
                })
        },
    },
}
</script>
